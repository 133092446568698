.header{
    display: flex;
    justify-content: center;
    text-align: center;
    color: #444;
}

.header h1 {
  font-size: 5rem;
  margin-bottom: 0rem;
  border-bottom: 2px solid #008CBA; 
  padding-bottom: 0.5rem;
}

.login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 40px;
    width: 30vw;
  }
  
  .login-header {
    text-align: center;
    color: #444;
  }
  
  .login-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .login-label {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .login-input {
    padding: 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    outline: none;
    width: 100%;
  }
  
  .login-button {
    padding: 1rem 2rem;
    margin: 0 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #008CBA;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .login-button:hover {
    background-color: #005f6b;
  }

  .register-link-container{
    font-size: 20px;
  }