.register-container {
    height: 100vh;
    display: flex;
    justify-content: center;
  }
  
  .register-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    padding: 40px;
    width: 30vw;
  }
  
  .register-header {
    text-align: center;
    color: #444;
  }
  
  .register-input-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .register-label {
    font-size: 1.2rem;
    margin-bottom: 5px;
  }
  
  .register-input {
    padding: 10px;
    font-size: 1.2rem;
    border-radius: 5px;
    border: 1px solid #d3d3d3;
    outline: none;
    width: 100%;
  }

  .register-button{
    padding: 1rem 2rem;
    margin: 0 1rem;
    border: none;
    border-radius: 0.5rem;
    background-color: #008CBA;
    color: white;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }

  .register-button:hover {
    background-color: #005f6b;
  }

  .register-link-container{
    font-size: 20px;
  }