.App {
    background-color: #f2f2f2;
    height: 100vh;
    display: flex;
    justify-content: center;
    overflow: scroll;
}

.App-header {
    text-align: center;
    color: #444;
    height: 100vh;
}

.App-header h1 {
    font-size: 2rem !important;
    margin-bottom: 2rem;
    border-bottom: 2px solid #008CBA; 
    padding-bottom: 0.5rem;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100vw;
}

.card {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin: 10px;
    padding: 10px;
    width: 20%;
    height: 20rem;
}
.card-body {
    flex: 1;
    padding: 1rem;
}

.card-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
}

.card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.card p {
    font-size: 1.2rem;
    margin-bottom: 0.4rem;
}

.join{
    position: relative;
    left: 20%;
    background-color: black;
    border: none;
    border-radius: 5%;
}

.joined{

    position: relative;
    left: 20%;
}

.participants{
    position: relative;
    right: 20%;
    background-color: black;
    border: none;
    border-radius: 5%;
}

.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popup-content {
    background-color: white;
    border-radius: 5px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    width: 400px;
}

.popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.popup-header h2 {
    margin: 0;
}

.close-button {
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: gray;
}

.popup-body {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 1.8rem;
}

.popup-body2 {
    display: flex;
    flex-direction: column;
    align-items:start;
}

.list-elementss{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center ;
    width: 100%;
    border-bottom: 2px solid #008CBA;
}

.list-elementss h2, p{
    margin: 0.4rem;
    font-size: 1.5rem;
}

.list-elementss p{
    margin: 0.4rem;
    font-size: 1.2rem;
}

label {
    font-weight: bold;
}

input {
    padding: 5px;
    border: 1px solid gray;
    border-radius: 5px;
}

.submit-button {
    background-color: #3f51b5;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
}

.submit-button:hover {
    background-color: #303f9f;
}

.nav-headers{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    
}

.nav-buttons {
    color: white;
    text-decoration: none;
    font-size: 150%;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
    background-color: #333;
    margin-left: 1rem;
    transition: background-color 0.2s ease-in;
  }

  .nav-buttons.selected {
    background-color: #008CBA; 
    color: white;
  }
  