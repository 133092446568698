.App {
    background-color: #f2f2f2;
    height: 100vh;
    display: flex;
    justify-content: center;
}
  
.App-headerss {
    text-align: center;
    color: #444;
    height: 100vh;
}
  
.App-headerss h1 {
    font-size: 4rem !important;
    margin-bottom: 2rem;
    border-bottom: 2px solid #008CBA; 
    padding-bottom: 0.5rem;
}
  
select,input {
    padding: 0.5rem;
    margin: 1rem;
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}
  
select:focus {
    outline: none;
    box-shadow: 0 0 0 2px #008CBA;
}
  
button {
    border: none;
    border-radius: 0.5rem;
    background-color: #008CBA;
    color: white !important;
    font-size: 1.2rem;
    cursor: pointer;
    text-decoration: none !important;
}
  
button:hover {
    background-color: #005f6b;
    text-decoration: none !important;
}

#description{
    width: 75%;
    height: 3rem;
}