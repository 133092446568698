.nav{
    display: flex;
}

.nav-header{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 83%;
    position: relative;
    left: 9.5%;
}

.profile{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 17%;
    font-size: 150%;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
}

.nav-button {
    color: #333;
    text-decoration: none;
    font-size: 150%;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
    transition: all 0.1s ease-in-out;
  }
  
  .nav-button:hover {
    background-color: #333;
    color: #fff;
  }

